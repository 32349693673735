import { useCallback } from 'react';
import initI18n, { UseTranslationOptions } from '@pushpay/i18n';
import {
	useTranslation as useTranslationBase,
	NamespaceKey,
	Translate as TranslateBase,
} from './translation';

import { LanguageOptions } from '../webgiving-generated';
import { TranslationLogging } from './translation-logging';
import { isEmptyObject } from '../utils/language-utils';

// [FeatureFlagWillMakeThisCodeObsolete(FeatureName.WebGiving_i18nOptimization)] Start
declare const EN_TRANSLATION_HASH: string;
declare const ES_TRANSLATION_HASH: string;
// [FeatureFlagWillMakeThisCodeObsolete(FeatureName.WebGiving_i18nOptimization)] End

declare const WEBGIVING_DEFAULT_EN_TRANSLATION: string;
declare const WEBGIVING_ES_TRANSLATION_HASH: string;

export const translationLogging = new TranslationLogging();

export function useTranslation<TNamespaceKey extends NamespaceKey = 'common'>(
	namespace: TNamespaceKey = 'common' as TNamespaceKey,
	options?: UseTranslationOptions
) {
	const { translate, keyExists, i18n } = useTranslationBase(namespace, options);
	const { language, changeLanguage: changeLanguageI18n } = i18n;
	const changeLanguage = changeLanguageI18n.bind(i18n);

	const onLanguageChange = useCallback(
		(callback: (language: string) => void) => {
			i18n.on('languageChanged', callback);
			return () => i18n.off('languageChanged', callback);
		},
		[i18n]
	);

	const onFailedLoading = useCallback(
		(callback: (language: string, namespaces: string[], message: string) => void) => {
			i18n.on('failedLoading', callback);
			return () => i18n.off('failedLoading', callback);
		},
		[i18n]
	);

	return {
		translate,
		keyExists,
		changeLanguage,
		onFailedLoading,
		onLanguageChange,
		language,
	};
}

const namespace = 'common';
export const languageStorageKey = 'lang';
export type Translate = TranslateBase<'common'>;

export function initTranslation(defaultToEnglish?: boolean) {
	// translationLogging attached to window for easy access during development
	if (window.pushpay && translationLogging) {
		window.pushpay.translationLogging = translationLogging;
	}

	const { en, es } = LanguageOptions;
	const i18nInstance = initI18n({
		options: {
			debug: DEBUG,
			ns: [namespace],
			fallbackNS: namespace,
			load: 'all',
			defaultNS: namespace,
			lng: defaultToEnglish ? en.TwoLetterIsoCode : '',
			keySeparator: false,
			backend: {
				loadPath: (languages: string[], namespaces: string[]) => {
					const locale = !!languages
						? languages[0]
						: en.TwoLetterIsoCode;

					if (NewFeatures.WebGiving_i18nOptimization) {
						if (locale === es.TwoLetterIsoCode) {
							return `/dist/webgiving/translation/es/common.${WEBGIVING_ES_TRANSLATION_HASH}.json`;
						}
					} else {
						if (locale === es.TwoLetterIsoCode) {
							return `/dist/webgiving/translation/es/common.${ES_TRANSLATION_HASH}.json`;
						}

						return `/dist/webgiving/translation/en/common.${EN_TRANSLATION_HASH}.json`;
					}
				},
			},
			...(NewFeatures.WebGiving_i18nOptimization ? {
				partialBundledLanguages: true,
				resources: {
					en: {
						[namespace]: WEBGIVING_DEFAULT_EN_TRANSLATION,
					},
				},
			} : {}),
			saveMissing: true,
			saveMissingTo: 'current', // Ensures that the current lang is used in missingKeyHandler
			missingKeyHandler: (
				language: string[],
				namespace: string,
				key: string,
				fallbackValue: string
			) => {
				// Saved to storage in the development stage of the WG Spanish feature
				// To build up the translations dictionary in dev/qa https://pushpay.atlassian.net/browse/PP-37490
				if (
					(language[0] || LanguageOptions.en.TwoLetterIsoCode) ===
					LanguageOptions.es.TwoLetterIsoCode
				) {
					translationLogging.saveESKey(key);
				}
			},
		},
	});

	// moment locale switching on successfully loaded resources
	// Resources that timeout returns an empty object :/
	i18nInstance.on('languageChanged', (language) =>
		isEmptyObject(i18nInstance.getResourceBundle(language, namespace))
			? i18nInstance.changeLanguage(en.TwoLetterIsoCode)
			: moment.locale(language)
	);
}

export {
	ShouldTranslateProvider,
	useShouldTranslate,
	ShouldTranslate,
} from './should-translate-provider';
