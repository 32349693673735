import * as React from 'react';
import { observer } from 'mobx-react';
import { WebGivingUserAction } from '../webgiving-user-actions';
import { ModalType } from '../modals/modal-type';
import { injectWebGivingProps, InjectableWebGivingProps, raiseAction } from '../utils/injectable-props';
import { footerLink as footerLinkOld } from '../main/webgiving-main.less';
import { footerLink } from '../main/footer/footer.less';
import { Fragment } from '../../Shared/components/fragment';
import { PaymentLabel } from '../webgiving-generated';
import { Text } from '../components/text';

@observer
export class TermsAndConditionsBody extends React.Component<{
	hasCustomLinks: boolean;
	legalName: string;
	paymentLabel: PaymentLabel;
	termsAndConditionsUrl?: string;
	privacyStatementUrl?: string;
	supportsBackdrop: boolean; // [FeatureFlagWillMakeThisCodeObsolete(FeatureName.WebGiving_UIUpdates)]
}, {}> {
	render() {
		const { hasCustomLinks, legalName, paymentLabel, termsAndConditionsUrl, privacyStatementUrl, supportsBackdrop } = this.props;

		// tslint:disable:max-line-length
		if(NewFeatures.WebGiving_UpdateDefaultTermsAndPrivacy) {
			return (
				<p>
					<Text>By making this {paymentLabel.NounLowerCase}</Text>{
					!hasCustomLinks && <Fragment><Text> to </Text><strong>{legalName}</strong></Fragment>
				} <Text>I agree to</Text> {
					hasCustomLinks &&
					<Fragment><strong><Text params={{legalName}}>{`{{legalName}} 's `}</Text></strong><FooterModalLink supportsBackdrop={supportsBackdrop}
						data-pp-at-target="Merchant Terms"
						modalType={ModalType.MerchantTerms}><Text>Terms &amp; Conditions</Text></FooterModalLink><Text> and </Text><FooterModalLink supportsBackdrop={supportsBackdrop}
						data-pp-at-target="MerchantPrivacy" modalType={ModalType.MerchantPrivacy}><Text>Privacy
						Policy</Text></FooterModalLink><Text>, including </Text></Fragment>
				}
					<Text>the Pushpay </Text><a className={footerLink} href={termsAndConditionsUrl} target="_blank"
												rel="noopener noreferrer"><Text>Terms &amp; Conditions</Text></a><Text> and </Text><a
					className={footerLink} href={privacyStatementUrl} target="_blank" rel="noopener noreferrer"><Text>Privacy
					Policy</Text></a>
				</p>
			);
		} else {
			return (
				<p>
					<Text>By making this {paymentLabel.NounLowerCase}</Text>{
					!hasCustomLinks && <Fragment><Text> to </Text><strong>{legalName}</strong></Fragment>
				} <Text>I agree to</Text> {
					hasCustomLinks &&
					<Fragment><strong><Text params={{legalName}}>{`{{legalName}} 's `}</Text></strong><FooterModalLink supportsBackdrop={supportsBackdrop}
						data-pp-at-target="Merchant Terms"
						modalType={ModalType.MerchantTerms}><Text>Terms &amp; Conditions</Text></FooterModalLink><Text> and </Text><FooterModalLink supportsBackdrop={supportsBackdrop}
						data-pp-at-target="MerchantPrivacy" modalType={ModalType.MerchantPrivacy}><Text>Privacy
						Policy</Text></FooterModalLink><Text>, including </Text></Fragment>
				}
					<Text>the Pushpay </Text><FooterModalLink supportsBackdrop={supportsBackdrop} data-pp-at-target="Pushpay Terms"
															  modalType={ModalType.PushpayTerms}><Text>Terms &amp; Conditions</Text></FooterModalLink><Text> and </Text><FooterModalLink supportsBackdrop={supportsBackdrop}
					data-pp-at-target="Pushpay Privacy" modalType={ModalType.PushpayPrivacy}><Text>Privacy Policy</Text></FooterModalLink>
				</p>
			);
		}
		// tslint:enable:max-line-length
	}
}

@observer
export class TermsAndConditionsFooter extends React.Component<{
	hasCustomLinks: boolean;
	tradingName: string;
	termsAndConditionsUrl?: string;
	privacyStatementUrl?: string;
	supportsBackdrop: boolean; // [FeatureFlagWillMakeThisCodeObsolete(FeatureName.WebGiving_UIUpdates)]

}, {}> {
	render() {
		const { hasCustomLinks, tradingName, termsAndConditionsUrl, privacyStatementUrl, supportsBackdrop} = this.props;

		// tslint:disable:max-line-length
		if(NewFeatures.WebGiving_UpdateDefaultTermsAndPrivacy){
			return (
				<p>
					Pushpay <a className={footerLink} href={termsAndConditionsUrl} target="_blank" rel="noopener noreferrer"><Text>Terms &amp; Conditions</Text></a><Text> and </Text><a className={footerLink} href={privacyStatementUrl} target="_blank" rel="noopener noreferrer"><Text>Privacy Policy</Text></a>.
					{ hasCustomLinks && <Fragment><br/>{tradingName} <FooterModalLink supportsBackdrop={supportsBackdrop} data-pp-at-target="Merchant Terms" modalType={ModalType.MerchantTerms}><Text>Terms &amp; Conditions</Text></FooterModalLink><Text> and </Text><FooterModalLink supportsBackdrop={supportsBackdrop} data-pp-at-target="MerchantPrivacy" modalType={ModalType.MerchantPrivacy}><Text>Privacy Policy</Text></FooterModalLink>.</Fragment> }
				</p>
			);
		} else {
			return (
				<p>
					Pushpay <FooterModalLink supportsBackdrop={supportsBackdrop} data-pp-at-target="Pushpay Terms" modalType={ModalType.PushpayTerms}><Text>Terms &amp; Conditions</Text></FooterModalLink><Text> and </Text><FooterModalLink supportsBackdrop={supportsBackdrop} data-pp-at-target="Pushpay Privacy" modalType={ModalType.PushpayPrivacy}><Text>Privacy Policy</Text></FooterModalLink>.
					{ hasCustomLinks && <Fragment><br />{tradingName} <FooterModalLink supportsBackdrop={supportsBackdrop} data-pp-at-target="Merchant Terms" modalType={ModalType.MerchantTerms}><Text>Terms &amp; Conditions</Text></FooterModalLink><Text> and </Text><FooterModalLink supportsBackdrop={supportsBackdrop} data-pp-at-target="MerchantPrivacy" modalType={ModalType.MerchantPrivacy}><Text>Privacy Policy</Text></FooterModalLink>.</Fragment> }
				</p>
			);
		}
		// tslint:enable:max-line-length
	}
}

interface IFooterModalLinkProps {
	supportsBackdrop: boolean; // [FeatureFlagWillMakeThisCodeObsolete(FeatureName.WebGiving_UIUpdates)]
	modalType: ModalType;
}

@injectWebGivingProps
@observer
class FooterModalLink extends React.Component<IFooterModalLinkProps & InjectableWebGivingProps> {
	render() {
		return <a href="#" className={this.props.supportsBackdrop ? footerLink : footerLinkOld} onClick={this.handleClick}>{this.props.children}</a>;
	}

	private handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();

		const { modalType } = this.props;
		raiseAction(new WebGivingUserAction.OpenModalDialog(modalType), this.props);
	}
}
